/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var LinkedinIcon = createIconsComponent(_jsx("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M1.14 0H14.857C15.1615 0.00185152 15.4528 0.124378 15.6671 0.340709C15.8813 0.557041 16.0011 0.849518 16 1.154V14.846C16.0011 15.1505 15.8813 15.443 15.6671 15.6593C15.4528 15.8756 15.1615 15.9981 14.857 16H1.14C0.511 16 0 15.482 0 14.846V1.154C0 0.518 0.51 0 1.14 0ZM2.464 13.714H4.84V6.08H2.464V13.715V13.714ZM3.65 5.035C3.2944 5.02144 2.95789 4.87063 2.7111 4.61425C2.46431 4.35788 2.32645 4.01586 2.32645 3.66C2.32645 3.30414 2.46431 2.96212 2.7111 2.70574C2.95789 2.44937 3.2944 2.29856 3.65 2.285C4.407 2.285 5.025 2.903 5.025 3.66C5.025 4.42 4.411 5.035 3.65 5.035ZM11.354 13.714H13.725V9.528C13.725 7.471 13.278 5.888 10.879 5.888C9.725 5.888 8.949 6.521 8.632 7.121H8.6V6.078H6.325V13.714H8.696V9.935C8.696 8.939 8.886 7.975 10.121 7.975C11.336 7.975 11.353 9.114 11.353 9.999V13.714H11.354Z",
    fill: "none"
  })
}), 'LinkedinIcon', 'SocialMedia');
export default LinkedinIcon;