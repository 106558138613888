/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var FacebookIcon = createIconsComponent(_jsx("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M12.4468 19.9792C12.45 19.9497 12.4516 19.9196 12.4516 19.8893V12.6865H14.9133C15.308 12.6865 15.6633 12.4265 15.721 12.036C15.8164 11.39 15.7759 10.8026 15.7141 10.3849C15.6575 10.0014 15.3158 9.74892 14.9282 9.74892H12.4516C12.4516 7.52919 12.8209 7.2062 14.9026 7.1658C15.302 7.15806 15.6606 6.89428 15.7186 6.49892C15.816 5.83436 15.7772 5.25373 15.716 4.84525C15.6596 4.46794 15.3213 4.22199 14.9399 4.22532C11.2606 4.25742 9.16502 4.69252 9.16502 9.74892H7.25889C6.88845 9.74892 6.55956 9.98114 6.50351 10.3474C6.44316 10.742 6.40667 11.3139 6.50742 12.0096C6.56564 12.4115 6.92707 12.6865 7.33311 12.6865H9.16502V20.0008C6.31613 19.9821 4.27698 19.8696 2.9492 19.7617C1.48793 19.643 0.360453 18.5153 0.241729 17.0538C0.123591 15.5995 0 13.2919 0 10.0018C0 6.71167 0.123591 4.40402 0.241729 2.94972C0.360453 1.48819 1.48793 0.360517 2.9492 0.241772C4.40324 0.123608 6.71049 0 10 0C13.2895 0 15.5968 0.123613 17.0508 0.241772C18.512 0.360517 19.6396 1.4882 19.7583 2.94972C19.8764 4.40402 20 6.71167 20 10.0018C20 13.2919 19.8764 15.5995 19.7583 17.0538C19.6396 18.5154 18.512 19.643 17.0508 19.7617C15.9884 19.8481 14.4705 19.9373 12.4468 19.9792Z",
    fill: "none"
  })
}), 'FacebookIcon', 'SocialMedia');
export default FacebookIcon;