/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var TwitterIcon = createIconsComponent(_jsx("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M14.355 4.74001C14.365 4.88201 14.365 5.02401 14.365 5.16601C14.365 9.50301 11.066 14.5 5.035 14.5C3.2498 14.5044 1.50135 13.9929 0 13.027C0.264 13.057 0.518 13.068 0.792 13.068C2.26801 13.0715 3.70216 12.5776 4.863 11.666C4.17863 11.6535 3.51526 11.4275 2.96557 11.0196C2.41589 10.6118 2.00734 10.0424 1.797 9.39101C2 9.42101 2.203 9.44201 2.417 9.44201C2.711 9.44201 3.005 9.40201 3.279 9.33001C2.53627 9.1796 1.86854 8.77666 1.38927 8.18966C0.910009 7.60265 0.648787 6.86781 0.65 6.11001V6.07001C1.087 6.31401 1.595 6.46601 2.133 6.48701C1.68273 6.18762 1.31352 5.78149 1.05826 5.30482C0.802995 4.82814 0.669615 4.29573 0.67 3.75501C0.67 3.14501 0.832 2.58701 1.117 2.09901C1.94043 3.11309 2.96784 3.94275 4.13259 4.53417C5.29734 5.12558 6.5734 5.46555 7.878 5.53201C7.82542 5.28476 7.79828 5.03278 7.797 4.78001C7.79674 4.3492 7.8814 3.92256 8.04614 3.52449C8.21088 3.12643 8.45247 2.76474 8.7571 2.46011C9.06173 2.15548 9.42342 1.91389 9.82149 1.74915C10.2196 1.5844 10.6462 1.49974 11.077 1.50001C11.5259 1.49904 11.9703 1.59052 12.3823 1.76876C12.7944 1.94699 13.1653 2.20817 13.472 2.53601C14.2063 2.39454 14.9104 2.12656 15.553 1.74401C15.3088 2.50199 14.7964 3.14485 14.112 3.55201C14.772 3.48101 15.412 3.29801 16 3.04401C15.551 3.69785 14.9948 4.27124 14.355 4.74001Z",
    fill: "none"
  })
}), 'TwitterIcon', 'SocialMedia');
export default TwitterIcon;