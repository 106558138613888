import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import theme from '@segment/matcha/foundations/Theme';
import { withDesignTokens } from '../../useDesignTokens';
import * as views from './MenuList.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var MenuList = function MenuList(_ref) {
  var mainLinks = _ref.mainLinks,
      tokens = _ref.tokens;
  return _jsx(views.Menu, {
    columns: 1,
    overrides: {
      sm: 2,
      md: 4
    },
    children: mainLinks.map(function (_ref2) {
      var title = _ref2.title,
          links = _ref2.links;
      return _jsxs(views.MenuColumn, {
        children: [_jsx(views.MenuTitle, _objectSpread(_objectSpread({}, tokens), {}, {
          children: title
        })), _jsx(views.MenuLinks, {
          children: links.map(function (_ref3) {
            var title = _ref3.title,
                url = _ref3.url,
                rel = _ref3.rel;
            return _jsx(views.MenuItem, {
              children: _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens), {}, {
                href: url,
                rel: rel,
                children: title
              }))
            }, title);
          })
        })]
      }, title);
    })
  });
};

export default withDesignTokens(MenuList, function () {
  return {
    titleColor: theme.palette.brand.darkAcai[700],
    linkColor: theme.palette.brand.darkAcai[500],
    linkHoverColor: theme.palette.brand.darkAcai[700]
  };
}, {
  light: function light(theme) {
    return {
      titleColor: theme.palette.brand.darkAcai[700],
      linkColor: theme.palette.brand.darkAcai[500],
      linkHoverColor: theme.palette.brand.darkAcai[700]
    };
  },
  dark: function dark(theme) {
    return {
      titleColor: theme.palette.neutrals.white[100],
      linkColor: theme.palette.brand.darkAcai[400],
      linkHoverColor: theme.palette.neutrals.white[100]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      titleColor: theme.palette.neutrals.white[100],
      linkColor: theme.palette.twilio.gray[20],
      linkHoverColor: theme.palette.neutrals.white[100]
    };
  }
});