import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import theme from '@segment/matcha/foundations/Theme';
import { GithubIcon, LinkedinIcon, TwitterIcon } from '@segment/matcha/components/Icons/SocialMedia';
import { withDesignTokens } from '../../useDesignTokens';
import MenuList from './MenuList';
import useTrustArcLink from './useTrustArcLink';
import * as views from './Footer.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var FooterBase = function FooterBase(_ref) {
  var cta = _ref.cta,
      logo = _ref.logo,
      mainLinks = _ref.mainLinks,
      bottomLinks = _ref.bottomLinks,
      copyright = _ref.copyright,
      externalAppearance = _ref.appearance,
      className = _ref.className,
      tokens = _ref.tokens;
  var appearance = useAppearance(externalAppearance);
  var hasTrustArcEnabled = useTrustArcLink();
  return _jsx(AppearanceProvider, {
    appearance: appearance,
    children: _jsx(views.Footer, _objectSpread(_objectSpread({
      className: className
    }, tokens), {}, {
      children: _jsxs(views.Content, {
        children: [_jsxs(views.FooterTop, {
          columns: 1,
          overrides: {
            sm: 2
          },
          children: [_jsx(views.LogoSlot, {
            children: logo
          }), _jsx(views.CtaSlot, {
            children: cta
          })]
        }), mainLinks && _jsx(views.FooterContent, {
          children: _jsx(MenuList, {
            mainLinks: mainLinks
          })
        }), _jsxs(views.FooterBottom, {
          children: [_jsxs(views.BottomLinks, {
            children: [_jsx(views.MenuItem, {
              children: _jsx(views.Copyright, _objectSpread(_objectSpread({}, tokens), {}, {
                children: copyright
              }))
            }), bottomLinks.map(function (_ref2) {
              var title = _ref2.title,
                  url = _ref2.url;
              return _jsx(views.MenuItem, {
                children: _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens), {}, {
                  href: url,
                  children: title
                }))
              }, title);
            }), hasTrustArcEnabled && _jsx(views.MenuItem, {
              children: _jsx(views.MenuLinkTrustArc, _objectSpread(_objectSpread({}, tokens), {}, {
                id: "teconsent"
              }))
            })]
          }), _jsxs(views.SocialLinks, {
            children: [_jsx(views.SocialItem, _objectSpread(_objectSpread({}, tokens), {}, {
              children: _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens), {}, {
                href: "https://www.linkedin.com/company/segment-io",
                children: _jsx(LinkedinIcon, {
                  size: "small",
                  label: "Linkedin"
                })
              }))
            })), _jsx(views.SocialItem, _objectSpread(_objectSpread({}, tokens), {}, {
              children: _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens), {}, {
                href: "https://twitter.com/segment",
                children: _jsx(TwitterIcon, {
                  size: "small",
                  label: "Twitter"
                })
              }))
            })), _jsx(views.SocialItem, _objectSpread(_objectSpread({}, tokens), {}, {
              children: _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens), {}, {
                href: "https://github.com/segmentio",
                children: _jsx(GithubIcon, {
                  size: "small",
                  label: "Github"
                })
              }))
            }))]
          })]
        })]
      })
    }))
  });
};

export var Footer = withDesignTokens(FooterBase, function () {
  return {
    backgroundColor: theme.palette.neutrals.cloud[100],
    linkColor: theme.palette.brand.darkAcai[400],
    linkHoverColor: theme.palette.brand.darkAcai[800]
  };
}, {
  light: function light(theme) {
    return {
      backgroundColor: theme.palette.neutrals.cloud[100],
      linkColor: theme.palette.brand.darkAcai[400],
      linkHoverColor: theme.palette.brand.darkAcai[800]
    };
  },
  dark: function dark(theme) {
    return {
      backgroundColor: theme.palette.brand.darkAcai[700],
      linkColor: theme.palette.brand.darkAcai[400],
      linkHoverColor: theme.palette.neutrals.white[100]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      backgroundColor: theme.palette.twilio.gray[100],
      linkColor: theme.palette.twilio.gray[20],
      linkHoverColor: theme.palette.neutrals.white[100]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      backgroundColor: theme.palette.cdpWeek2023.gray[100],
      linkColor: theme.palette.cdpWeek2023.gray[20],
      linkHoverColor: theme.palette.neutrals.white[100]
    };
  }
});